<template>
	<main class="main">
    <div class="container">
      <Crumbs :data="crumbs"></Crumbs>
    </div>

		<section class="main__box">
			<div class="container">
				<h2 class="main__title">
					Последние новости
				</h2>
			</div>

			<NewsSlider></NewsSlider>
		</section>

		<div class="bg bg--gray last">
			<section class="main__box main__box--big">
				<NewsAll></NewsAll>
			</section>
		</div>
	</main>
</template>

<script>
import NewsSlider from '@/components/SliderNews.vue'
import NewsAll from '@/components/NewsAll.vue'
import Crumbs from '@/components/Crumbs'

export default {
	name: 'News',

	components: {
		NewsSlider,
		NewsAll,
		Crumbs,
	},
	data() {
	  return {
			crumbs: null,
    }
  },
  mounted() {
    this.crumbs = [
      {
        link: 'HolderHome',
        text: 'Главная',
      },
      {
        link: null,
        text: 'Новости',
      },
    ]
	},
}
</script>
